<template>
    <div v-if="report_settings">
        <PnlView
            v-if="category === 'pnl'"
            :report_settings="report_settings" />

    </div>
</template>

<script>
var encrypter = require('object-encrypter');
var enc_engine = encrypter('orkl', {ttl: false});

import PnlView from './Reports/PlReport/View.vue'

export default {
    components: {
        PnlView
    },
    props: {
        category: {
            required: true,
        },
        p: {
            required: true,
        },
    },

    data(){
        return {
            report_settings: {},
        }
    },

    mounted(){
        if (this.category && this.p){
            let q = decodeURIComponent(this.p)
            this.report_settings = enc_engine.decrypt(q)
            this.$emit('tab-title-change', this.report_settings.title)
        }
        
    }
}
</script>
